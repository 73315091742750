module.exports = [{
      plugin: require('/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography","omitGoogleFont":true},
    },{
      plugin: require('/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff5700","showSpinner":false},
    },{
      plugin: require('/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-smartypants","options":{"dashes":"oldschool"}},{"resolve":"gatsby-plugin-mdx-prismjs"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"sizeByPixelDensity":true}},{"resolve":"gatsby-remark-copy-linked-files","options":{}}]},
    },{
      plugin: require('/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","customEntries":[{"title":"Another Page","content":"Welcome to page 2","path":"/another-page/"}]}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"path","store":true},{"name":"content"},{"name":"tags"}],"resolvers":{"Mdx":{}}},
    },{
      plugin: require('/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
