// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-single-template-tsx": () => import("/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/src/templates/SingleTemplate.tsx" /* webpackChunkName: "component---src-templates-single-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/src/templates/IndexTemplate.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/src/templates/TagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-another-page-tsx": () => import("/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/src/pages/another-page.tsx" /* webpackChunkName: "component---src-pages-another-page-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/pieterbos/Documents/projects/protorabbit/verwonderwinkel/verwonderwinkel/.cache/data.json")

